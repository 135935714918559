<template>
  <div>
    <div class="page-header clear-filter" filter-color="orange">
      <parallax
        class="page-header-image"
        style="background-image:url('img/header.jpg')"
      >
      </parallax>
      <div class="container">
        <div class="content-center brand">
          <img class="logo" src="img/KenonazLogoMark.png" alt="" />
          <h1 class="h1-seo">Kenonaz</h1>
          <h3>Ice Hockey Statistics.</h3>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="section section-images">
          <div class="row">
            <div class="col-md-12">
              <div class="hero-images-container">
                <img src="img/screenshot_iphone_recording.png" alt="" />
              </div>
              <div class="hero-images-container-1">
                <img src="img/screenshot_ipad_stats.png" alt="" />
              </div>
            </div>
          </div>
      </div>
    </div>
    <record-statistics-section></record-statistics-section>
    <game-statistics-section></game-statistics-section>
    <share-section></share-section>
    <prepare-for-next-game-section></prepare-for-next-game-section>
  </div>
</template>
<script>
import { Parallax } from '@/components';
import RecordStatisticsSection from './components/RecordStatisticsSection.vue';
import GameStatisticsSection from './components/GameStatisticsSection.vue';
import ShareSection from './components/ShareSection.vue';
import PrepareForNextGameSection from './components/PrepareForNextGameSection.vue';

export default {
  name: 'index',
  bodyClass: 'index-page',
  components: {
    Parallax,
    RecordStatisticsSection,
    GameStatisticsSection,
    ShareSection,
    PrepareForNextGameSection
  }
};
</script>
<style>
.cta {
  font-size: 1.5em;
}
.cta::after {
  content: " →"
}
</style>
