<template>
  <div class="section game-stats-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <div class="game-stats-section__screenshot">
            <img src="img/screenshot_ipad_stats.png" alt="" />
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <h2 class="title">Statistics</h2>
          <h5 class="description">
            View important statistics of your games. Get the insight you need to improve the performance based on data.
          </h5>
          <router-link
            to="/contactus"
            class="cta"
          >
            Contact us
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
