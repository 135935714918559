<template>
  <div :style="styles">
    <slot></slot>
  </div>
</template>
<script>
function debounce(func, wait, immediate) {
  let timeout;
  return function() {
    const context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}
export default {
  name: 'parallax',
  data() {
    return {
      styles: {},
      debounceTimeout: 6
    };
  },
  computed: {
    isSafari () {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf('safari') != -1) {
        if (ua.indexOf('chrome') <= -1) {
          return true;
        }
      }
      return false
    }
  },
  methods: {
    handleScroll(scrollVal) {
      let oVal = scrollVal / 3;
      this.styles = {
        transform: `translate3d(0, ${oVal}px,0)`
      };
    },
    checkForParallax(scrollVal) {
      let fn = debounce(
        () => this.handleScroll(scrollVal),
        this.debounceTimeout
      );
      fn();
    }
  },
  mounted() {
    let self = this;
    if (!this.isSafari) {
      window.addEventListener('scroll', function() {
        let scrollVal = this.scrollY;
        self.checkForParallax(scrollVal);
      });
    } else {
      this.styles = {
        "height": "100vh",
        "max-height": "1280px"
      };
    }
  }
};
</script>
