<template>
  <div>
    <div class="page-header page-header-small" filter-color="orange">
      <parallax
        class="page-header-image"
        style="background-image:url('img/75.jpg'); background-position: center top;"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1>About us</h1>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <div class="about__profile">
              <img class="profile" src="img/Barbi.jpg" alt="" />
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <h3 class="about__profile__name">Barbara Südy</h3>
            <h5 class="description">
              I am a sport enthusiast Data Scientist who loves to play around with statistics. Out of all team sports ice hockey has always been my biggest passion. As a Data Scientist I have seen in many industries that decisions based on real data always lead to better results than relying on gut feeling. Why would it be different in sports? Our aim with Kenonaz is to provide hockey coaches and players with useful metrics, insights and analytic tools which allows them to make the best decisions.
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <h3 class="about__profile__name">Barnabás Südy</h3>
            <h5 class="description">
              I have been an Ice Hockey fan since I was young. Even if I never played this game on a high level it was my favorite. 
              Professionally I am a Software Engineer and I am very keen to help my favorite sport with my knowledge.
            </h5>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="about__barny__profile">
              <img class="profile" src="img/Barny.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'about',
  bodyClass: 'about-page',
  components: {
  }
};
</script>
<style>
.about__profile {
  text-align: center;
  margin: 30px 0;
}

.about__profile__name {
  font-weight: 700;
  margin: 30px 0;
}

img.profile {
  height: 25em;
}
</style>
