<template>
  <div class="page-header clear-filter" filter-color="orange">
    <div
      class="page-header-image"
      style="background-image: url('img/hockey-rink-dimensions.jpg')"
    ></div>
    <div class="content">
      <div class="container">
        <div class="col-md-5 ml-auto mr-auto">
            <h2>Contact us</h2>

            <div>If you are interested in getting insights of your team's performance, please contact us.</div>

            <div>Alternatively you can email us: <a class="contact-us__email" href="mailto:contact@kenonaz.com">contact@kenonaz.com</a></div>

          <form ref="ContactUsForm" @submit.prevent="onContactUs">
              <card type="registration" plain>
              <!-- <div slot="header" class="logo-container">
                <img v-lazy="'img/now-logo.png'" alt="" />
              </div> -->

              <div class="input-label">Name</div>
              <fg-input
                class="no-border input-lg"
                addon-left-icon="las la-user"
                placeholder="Name"
                name="name"
                required
                :error="errors.name"
              >
                <!-- <template v-slot:label>Name</template> -->
              </fg-input>

              <div class="input-label">E-mail</div>
              <fg-input
                class="no-border input-lg"
                addon-left-icon="las la-at"
                placeholder="E-mail"
                name="email"
                type="email"
                required
                :error="errors.email"
              >
              </fg-input>

              <div class="input-label">Team</div>
              <fg-input
                class="no-border input-lg"
                addon-left-icon="las la-users"
                placeholder="Team"
                name="team"
              >
              </fg-input>

              <div class="input-label">Country</div>
              <fg-input
                class="no-border input-lg"
                addon-left-icon="las la-globe"
                placeholder="Country"
                name="country"
              >
              </fg-input>

              <template slot="raw-content">
                <div class="card-footer text-center">
                  <button
                    class="btn btn-primary btn-round btn-lg btn-block"
                    @click.prevent="onContactUs"
                  >
                    <span class="spin" v-show="loading"></span>
                    Contact up
                  </button>
                </div>
              </template>
            </card>
          </form>
        </div>
      </div>
    </div>

    <!-- Classic Modal -->
    <modal :show.sync="successModal" headerClasses="justify-content-center" class="modal-default">
      <h4 slot="header" class="title title-up">Thank you</h4>
      <p>
        Thank you for contacting us. We are going to get back to you as soon as possible.
      </p>
      <template slot="footer">
        <n-button type="danger" @click.native="successModal = false"
          >Close</n-button
        >
      </template>
    </modal>

    <modal
      :show.sync="errorModal"
      class="modal-primary"
      :show-close="false"
      headerClasses="justify-content-center"
      type="mini"
    >
      <div slot="header" class="modal-profile">
        <i class="las la-heart-broken"></i>
      </div>
      <p>Sorry, something went wrong. Hopefully we will fix it fast. Please try it again a bit later.</p>
      <template slot="footer">
        <button @click="errorModal = false" class="btn btn-danger btn-round" style="opacity: 1;">
          Close
        </button>
      </template>
    </modal>

  </div>
</template>
<script>
import { Card, Button, FormGroupInput, Modal } from '@/components';
export default {
  name: 'contact-us-page',
  bodyClass: 'contact-us-page',
  components: {
    Card,
    Modal,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data: () => {
    return {
      successModal: false,
      errorModal: false,
      loading: false,
      errors: {
        name: undefined,
        email: undefined
      }
    };
  },
  methods: {
    resetError () {
      this.errors.name = undefined;
      this.errors.email = undefined;
    },
    validateInput (data) {
      let result = true;
      this.resetError();
      const nameValue = data.get("name");
      if (nameValue.trim() == "") {
        this.errors.name = "Please provide your name";
        result = false;
      }

      if (!(/^[-0-9A-Za-z!#$%&'*+/=?^_`{|}~.]+@[-0-9A-Za-z!#$%&'*+/=?^_`{|}~.]+/.test(data.get("email")))) {
        this.errors.email = "Please provide your email address";
        result = false;
      }
      return result;
    },
    async onContactUs () {
      try {
        this.loading = true;
        const form = this.$refs.ContactUsForm;
        const data = new FormData(form);
        
        if (!this.validateInput(data)) {
          return;
        }
        await this.ContactUsRequest(data);
        this.successModal = true;
      } catch(e) {
        // eslint-disable-next-line no-console
        console.error('ContactUs failed', e);
        this.errorModal = true;
      } finally {
        this.loading = false;
      }
      
    },
    ContactUsRequest (data) {
      return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.open("POST", "https://formspree.io/f/xgeprewj");
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = function() {
          if (xhr.readyState !== XMLHttpRequest.DONE) return;
          if (xhr.status === 200) {
            resolve(xhr);
          } else {
            reject(xhr);
          }
        };
        xhr.send(data);
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.modal-buttons,
.popover-buttons,
.tooltip-buttons {
  .btn + .btn {
    margin-left: 3px;
  }
}

.contact-us__email { 
  &:link, &:visited, &:hover, &:active {
    color: #bbb;
    text-decoration: none;
  }
}

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
.spin {
  display: inline-block;
  position: relative;
  width: 1em;
  height: 1em;
}

.spin::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
	border: solid 3px #cfd0d1;
	border-bottom-color: #1c87c9;
	border-radius: 50%;
	content: "";
	height: 100%;
	width: 100%;
	position: absolute;
	top: 50%;
	left: calc(50% - 5px);
	-webkit-transform: translate3d(-50%, -50%, 0);
	transform: translate3d(-50%, -50%, 0);
	will-change: transform;
	display: block;
}

</style>
