<template>
  <navbar
    position="fixed"
    type="primary"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <template>
      <router-link class="navbar-brand" to="/">
        <img class="logo" src="img/KenonazLogoMark.png" alt="" />
        Kenonaz
      </router-link>
    </template>
    <template slot="navbar-menu"><li class="nav-item">
        <router-link
          class="nav-link btn btn-round btn-neutral"
          to="/contactus"
        >
          Contact Us
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/about">
          About
        </router-link>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Hockey Statistics"
          data-placement="bottom"
          href="https://hstat.kenonaz.com"
          target="_blank"
        >
          HStat
        </a>
      </li>
    </template>
  </navbar>
</template>

<script>
import { DropDown, Navbar, NavLink } from '@/components';
export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    Navbar
  }
};
</script>

<style scoped>
.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-brand .logo {
  max-height: 1.5em;
  margin-right: 0.2em;
}
</style>
