<template>
  <div class="section recording-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <h2 class="title">Recording</h2>
          <h5 class="description">
            Kenonaz enables to record all important events of your games on an easy to use UI. You don't need anything else just a smartphone with internet connectivity and a Kenonaz account.
          </h5>
          <router-link
            to="/contactus"
            class="cta"
          >
            Contact us
          </router-link>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="recording-section__screenshot">
            <img src="img/recording-match-small.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style>
</style>
