<template>
  <div class="section share-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <h2 class="title">Sharing</h2>
          <h5 class="description">
            Share the knowledget with your team. Show the key metrics to everyone. Enable your players to improve by sharing their key moments in the game.
          </h5>
          <router-link
            to="/contactus"
            class="cta"
          >
            Contact us
          </router-link>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="share-section__screenshot">
            <img src="img/training-small.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style>
</style>
